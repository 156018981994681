.title {
  .set-size(@fs, @lh, @line-margin-offset: 2) {
    font-size: @fs;
    line-height: @lh;
    margin-bottom: floor((@fs * @lh) / 2);

    .title__line {
      margin-top: floor((@fs * @lh) / @line-margin-offset);
    }
  }

  .set-default-size() {
    .set-size(@title-font-size-mobile-base, @title-line-height-base);

    @media(min-width: @screen-sm-min) {
      .set-size(@title-font-size-base, @title-line-height-base);
    }
  }

  .set-small-size() {
    .set-size(@title-font-size-mobile-small, @title-line-height-small);
    font-weight: @font-weight-bold;

    @media(min-width: @screen-sm-min) {
      .set-size(@title-font-size-small, @title-line-height-small);
    }
  }

  .set-medium-size() {
    .set-size(@title-font-size-mobile-medium, @title-line-height-medium);

    font-weight: @font-weight-xlight;

    b,
    strong {
      font-weight: @font-weight-semibold;
    }

    @media(min-width: @screen-sm-min) {
      .set-size(@title-font-size-medium, @title-line-height-medium);
    }
  }

  .set-large-size() {
    .set-size(@title-font-size-mobile-large, @title-line-height-large, 8);

    font-weight: @font-weight-xlight;

    b,
    strong {
      font-weight: @font-weight-semibold;
    }

    @media(min-width: @screen-sm-min) {
      .set-size(@title-font-size-large, @title-line-height-large, 8);
    }
  }

  .set-default-size();
  margin-top: 0;
  margin-bottom: 0;
  font-weight: @font-weight-light;
  text-transform: uppercase;
  display: block;

  &__line {
    position: relative;
    line-height: 0;

    &:before {
      .size(52px, 1px);
      background-color: @text-color;
      display: inline-block;
      content: '';
    }
  }

  &--small {
    .set-small-size();
  }

  &--medium {
    .set-medium-size();
  }

  &--large {
    .set-large-size()
  }
}
