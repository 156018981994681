@keyframes scrolldown {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.scroll-down {
  .set-color(@color, @ico) {
    color: @color;

    &:after {
      background-image: data-uri('image/svg+xml;charset=UTF-8', @ico);
    }
  }

  .set-default-color() {
    .set-color(@text-color, '../img/scroll-down-dark.svg');
  }

  .set-light-color() {
    .set-color(#fff, '../img/scroll-down-light.svg');
  }

  .set-default-color();
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: 0;
  white-space: nowrap;
  display: inline-block;
  line-height: 1;
  text-transform: uppercase;
  font-weight: @button-font-weight;
  font-size: @button-font-size;
  margin-bottom: @line-height-computed;
  text-align: center;
  -webkit-font-smoothing: antialiased;

  &:after {
    .square(32px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 4px auto 0;
    display: block;
    content: '';
    animation: scrolldown 2s infinite;
  }

  &--light {
    .set-light-color();
  }
}
