.media-box-list {
  .media-box:nth-child(even) {
    .media-box.set-even-position();
  }

  @media (max-width: @screen-sm-max) {
    .media-box {
      margin-bottom: 0;
    }

    + .section--primary {
      padding-top: 0;
    }
  }

  @media (min-width: @screen-md-min) {
    + .section--default {
      padding-top: 0;
    }
  }
}
