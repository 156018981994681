.sharer {
  .set-light-color() {
    color: #fff;

    a {
      color: #fff;
      transition: opacity .2s ease;

      &:hover {
        opacity: .8;
      }
    }
  }

  text-transform: uppercase;
  font-weight: @font-weight-bold;
  font-size: 15px;

  ul {
    .list-inline();
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;

    li {
      padding: 0 7px;
    }

    a {
      transition: color .2s ease;
    }
  }

  &--light {
    .set-light-color();
  }
}

.sharer-dropdown {
  .set-light-color() {
    button {
      color: #fff;

      &:hover {
        color: @text-color;
      }
    }
  }

  button {
    background-color: transparent;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    font-size: 26px;
    transition: color .2s ease;

    &:hover {
      color: @link-color;
    }
  }

  .dropdown-menu {
    border-radius: 0;
    padding: 0;

    > li {
      border-bottom: 1px solid @dropdown-border;

      &:last-child {
        border-bottom: 0;
      }

      > a {
        color: @text-color;
        padding: 10px 15px;
        text-transform: none;
      }
    }
  }
}
