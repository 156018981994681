.timeline {
  .list-unstyled();
  .make-row();
  .container();
  position: relative;
  display: block;
  padding-top: 75px;

  > * + * {
    margin-top: 70px;
  }

  &:before {
    .square(20px);
    position: absolute;
    top: 0;
    background: @text-color;
    border-radius: 100%;
    content: '';
    display: block;
    margin-left: 0;
  }

  &:after {
    position: absolute;
    width: 2px;
    top: 24px;
    bottom: 6px;
    background-color: @text-color;
    content: '';
    display: block;
    margin-left: 9px;
  }

  &__year {
    .title();
    .title.set-large-size();
    position: relative;
    display: block;
    line-height: 0.75 !important;
    white-space: nowrap;

    &:before {
      .size(14px, 1px);
      position: absolute;
      background-color: @text-color;
      content: '';
      display: block;
      top: 50%;
      margin-top: 1px;
      margin-bottom: 1px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &__title {
    .title();
    .title.set-small-size();
  }

  &__image {
    width: 100%;
  }

  &__milestone {
    position: relative;
    display: block;
    padding-left: 40px;
    padding-right: (@grid-gutter-width / 2);

    &:before,
    &:after {
      position: absolute;
      content: '';
      display: block;
      top: 4px;
      left: 10px;
    }

    &:before {
      width: 2px;
      background-color: #fff;
      height: 22px;
      margin: 0 -1px;
      z-index: 1;
    }

    &:after {
      .square(8px);
      border-radius: 100%;
      background-color: @brand-primary;
      margin: 7px -4px 0;
      z-index: 2;
    }

    > * {
      margin: 0;

      + * {
        margin-top: 18px;
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    &__milestone {
      &:before,
      &:after {
        top: 10px;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    margin: 0 auto;
    padding-top: 125px;

    &:before,
    &:after {
      left: 50%;
    }

    &:before {
      margin-left: -10px;
    }

    &:after {
      margin-left: -1px;
    }

    > * + * {
      margin-top: 0;
    }

    &__milestone {
      .make-md-column(6);
      float: none !important;
      padding-left: (@grid-gutter-width / 2);

      > * + * {
        margin-top: 30px;
      }

      &:before,
      &:after {
        top: 10px;
        left: auto;
      }

      &:nth-child(odd) {
        text-align: right;
        padding-right: 84px;

        &:before,
        &:after,
        .timeline__year::before {
          right: 0;
        }
      }

      &:nth-child(even) {
        .make-md-column-offset(6);
        padding-left: 84px;

        &:before,
        &:after {
          left: 0;
        }
      }
    }

    &__year::before {
      width: 40px;
      margin-left: -60px;
      margin-right: -60px;
    }
  }
}
