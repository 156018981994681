.product-print {
  display: none;

  &__col-1 {
    float: left;
    width: 40%;
    padding-right: 16pt;
  }

  &__col-2 {
    float: left;
    width: 60%;
  }

  &__col {
    margin-bottom: 40px;
  }

  &__gallery {
    position: relative;
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__image {
    position: relative;
    width: 33.33333%;
    margin-bottom: 16pt;
    padding: 0 15px;

    img {
      border: 1pt solid #ddd;
      width: 100%;
      height: auto;
      display: block;
    }

    div {
      padding: 2px;
      font-size: 90%;

      p {
        margin: 0;
      }
    }
  }

  &__logo {
    display: block;
    width: 180px;
    height: auto;
    margin-bottom: 32px;
  }

  &__company {
    font-size: 80%;

    ul {
      .list-inline();
    }
  }
}

.print-product-button {
  color: inherit;
  font-size: 26px;
  transition: color .2s ease;
  outline: 0;

  &:hover,
  &:focus {
    outline: 0;
    color: #000;
  }
}

@media print {
  .product-print {
    display: block;
  }

  #header,
  #sub-header,
  #outdated,
  #footer,
  .section {
    display: none !important;
  }
}
