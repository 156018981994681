.cta-list {
  .make-row(0);
  position: relative;
  background-color: @text-color;

  > *[class^="col-"] {
    padding-left: 0;
    padding-right: 0;
  }

  @supports (display: flex) {
    @media (min-width: @screen-md-min) {
      display: flex;

      &:before,
      &:after {
        display: none;
      }

      .cta {
        height: 100%;
      }
    }
  }
}
