.button {
  .set-color(@color, @color-hover: @text-color) {
    color: @color;
    border-color: @color;

    &:hover {
      background-color: @color;
      color: @color-hover;
    }
  }

  .set-hollow-color() {
    .set-color(#fff);
  }

  .set-default-color() {
    .set-color(@text-color, #fff);
  }

  .set-default-color();
  position: relative;
  background-color: transparent;
  white-space: nowrap;
  display: inline-block;
  line-height: 1;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  font-weight: @button-font-weight;
  font-size: @button-font-size;
  padding: @button-mobile-padding;
  margin-bottom: @line-height-computed;
  text-align: center;
  transition: color .2s ease, background-color .2s ease, border-color .2s ease;
  text-decoration: none !important;
  overflow: hidden;

  &--hollow {
    .set-hollow-color();
  }

  &[disabled] {
    pointer-events: none;
  }

  &.has-loader {
    text-indent: -9999px;
    background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/loader-primary.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
  }

  @media (min-width: @screen-sm-min) {
    min-width: @button-min-width;
    width: auto;
    padding: @button-padding;
  }
}
