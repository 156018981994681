.page-header {
  position: relative;
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background-color: @brand-primary;

  .scroll-down {
    .scroll-down.set-light-color();
  }

  .button {
    .button.set-hollow-color();
    margin-bottom: 0;
  }

  &__image {
    .square(100%);
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    html.desktop & {
      background-attachment: fixed;
    }

    &:before {
      .square(100%);
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-color: #000;
    }
  }

  &__content {
    .container();
    position: relative;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 80px;

    h1,
    h3 {
      .title();
      margin-bottom: 0;
    }

    h3 {
      .title.set-small-size();
      margin-top: 0;
    }

    h1 {
      .title.set-large-size();
      margin-top: 50px;
    }

    p {
      margin-top: 34px;
      margin-bottom: 0;
    }
  }

  @media (min-width: @screen-sm-min) {
    min-height: 600px;

    &__content {
      padding-top: 160px;
      padding-bottom: 160px;
    }
  }



  @media (min-width: @screen-md-min) {
    .size(100%, 100vh);
    font-size: 20px;
    display: table;

    &__content {
      vertical-align: middle;
      display: table-cell;
      padding-top: 0;
      padding-bottom: 0;

      h3 {
        margin-top: -120px;
      }

      h1 {
        margin-top: 90px !important;
      }
    }

    .scroll-down {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
    }
  }
}
