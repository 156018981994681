.cta {
  position: relative;
  text-align: center;
  color: #fff;
  background-color: @text-color;
  display: block;
  text-decoration: none;
  transition: color .2s ease, background-color .2s ease;
  padding: 120px 0;

  &__wrapper {
    padding: 0 @grid-gutter-width;
    display: block;

    h1,
    h2,
    h3 {
      .title();
      .title.set-large-size();
    }

    > * {
      margin-bottom: 0 !important;

      + * {
        margin-top: 50px !important;
      }
    }

    .button {
      .button.set-hollow-color();
      margin-bottom: 0;
      display: inline-block;
      pointer-events: none;
    }
  }


  &:hover,
  &:focus {
    background-color: @brand-secondary;
    text-decoration: none;
    color: @text-color;

    .button {
      .button.set-color(@text-color);
    }
  }

  @media (min-width: @screen-sm-min) {
    width: 100%;
    height: 476px;
    display: table;

    &__wrapper {
      position: relative;
      display: table-cell;
      vertical-align: middle;

      > * + * {
        margin-top: 64px !important;
      }
    }
  }
}
