#header {
  position: relative;
  text-transform: uppercase;
  z-index: 100;

  .container-fluid {
    padding-right: round(@grid-gutter-width / 2);
    padding-left: round(@grid-gutter-width / 2);
  }

  .header__wrapper {
    background-color: #fff;
  }

  .language-dropdown {
    position: relative;
    top: -1px;
  }

  a {
    transition: color .2s ease;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .header__logo {
    padding: 10px 0;
    display: inline-block;

    img {
      height: 40px;
    }
  }

  .header__menu {
    font-size: 14px;

    nav {
      a {
        color: @text-color;
        font-weight: 700;

        &:hover,
        &.active {
          color: @brand-primary;
        }
      }

      > ul {
        .list-unstyled();
        margin-bottom: 0;
      }
    }

    .hamburgher {
      .square(30px);
      position: absolute;
      right: 15px;
      top: 20px;
      padding: 0;
      margin: 0;
      outline: 0;
      border: 0;
      background-color: transparent;

      .line,
      &:before,
      &:after {
        .size(100%, 2px);
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        background-color: @brand-primary;
        display: block;
        content: '';
        transition: all .3s ease;
      }

      &:before {
        transform: translateY(-8px);
      }

      &:after {
        transform: translateY(8px);
      }

      &.toggle {
        .line { opacity: 0 }

        &:before {
          transform: translateY(0) rotate(45deg);
        }

        &:after {
          transform: translateY(0) rotate(-45deg);
        }
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    height: @header-xs-height;

    .header__wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }

    .header__menu {
      text-align: right;

      nav {
        display: none;

        > ul {
          padding: 12px 0;

          > li {
            padding: 5px 0;
          }
        }
      }
    }

    &.toggle .header__wrapper {
      height: 100%;
      overflow-y: auto;

      .language-dropdown ul {
        margin-bottom: 30px;
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    .header__logo {
      display: block;
      text-align: center;
      padding-top: 15px;

      img {
        height: 68px;
      }
    }

    .header__menu {
      padding-top: 10px;
      text-align: center;
      margin-bottom: 8px;

      .quote,
      .language,
      .hamburgher {
        display: none;
      }

      nav {
        display: block !important;

        > ul {
          .list-inline();
          margin-left: 0;

          > li {
            padding: 0 2px;
          }
        }
      }
    }
  }

  @media (min-width: @screen-md-min) {
    .header__logo {
      float: left;
      padding: 10px 0;

      img {
        height: 42px;
      }
    }

    .header__menu {
      font-size: 14px;
      padding-top: 20px;
      margin-bottom: 0;
      float: right;

      nav > ul {
        margin-left: 0;
        margin-right: -10px;

        > li {
          padding: 0 6px;
        }
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    .header__logo {
      padding: 14px 0;

      img {
        height: 60px;
      }
    }

    .header__menu {
      font-size: 15px;
      padding-top: 30px;

      nav > ul {
        margin-left: 0;

        > li {
          padding: 0 14px;
        }
      }
    }
  }
}

#header + .page-header,
#header + .page-header-slider {
  @media (min-width: @screen-md-min) {
    height: ~"calc(100vh - @{header-md-height})";
  }

  @media (min-width: @screen-lg-min) {
    height: ~"calc(100vh - @{header-lg-height})";
  }
}
