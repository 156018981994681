.text-block {
  .set-line-color(@color: @text-color) {
    h1 + p::before,
    h2 + p::before,
    h3 + p::before,
    h4 + p::before {
      background-color: @color;
    }
  }

  .set-line-color();

  h1,
  h2,
  h3,
  h4 {
    .title();
  }

  h1 {
    .title.set-large-size();

    + p {
      margin-top: -0.3em;
    }
  }

  h3 {
    .title.set-small-size();
  }

  h1 + p::before,
  h2 + p::before,
  h3 + p::before,
  h4 + p::before {
    .size(52px, 1px);
    display: block;
    content: '';
    margin-bottom: 45px;
  }

  h2 + p,
  h3 + p {
    margin-top: 26px;
  }

  p > img {
    max-width: 100%;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .section--centered & {
    text-align: center;

    h1 + p::before,
    h2 + p::before,
    h3 + p::before,
    h4 + p::before {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--no-line {
    h1 + p::before,
    h2 + p::before,
    h3 + p::before,
    h4 + p::before {
      margin-bottom: 0 !important;
      height: round(@line-height-computed / 2);
      background-color: transparent !important;
    }
  }
}
