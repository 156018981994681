.contact-box {
  position: relative;
  background-color: @brand-primary;
  color: #fff !important;
  display: block;
  padding: 56px 100px 56px 40px;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #fff;

    &:after {
      color: #fff;
    }
  }

  &__role {
    .title();
    .title.set-small-size();
    color: @text-color;
    margin-bottom: 4px !important;
    // white-space: nowrap;
  }

  &:after {
    .fa();
    position: absolute;
    content: @fa-var-envelope;
    top: 50%;
    right: 56px;
    transform: translateX(50%) translateY(-50%);
    font-size: 30px;
    color: @text-color;
    transition: color .2s ease;
  }

  @media (min-width: @screen-sm-min) {
    &:after {
      font-size: 40px;
    }
  }
}
