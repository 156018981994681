.swiper-zoom-container {
    width: 100%;
    height: 100%;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;

    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    text-align: center;
    > img, > svg, > canvas {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}