.step-box {
  .set-odd-position() {
    @media (min-width: @screen-md-min) {
      .step-box__head {
        left: @grid-gutter-width;
      }

      .step-box__body {
        padding-right: 120px;
        padding-left: (100%/4);
      }
    }
  }

  .set-even-position() {
    @media (min-width: @screen-md-min) {
      .step-box__head {
        right: @grid-gutter-width;
        left: auto;
      }

      .step-box__body {
        padding-left: 120px;
        padding-right: (100%/4);
      }
    }
  }

  .make-row(0);
  position: relative;
  color: #fff;

  &__icon {
    position: relative;
    width: 100%;
    text-align: center;
    background-color: @brand-primary;
    overflow: hidden;
    padding-bottom: 62%;

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3D(-50%,-50%,0);
      text-transform: uppercase;
      font-weight: @font-weight-black;


      img {
        width: 100%;
        max-width: 60px;
        display: block;
        margin: 0 auto floor(@font-size-base * @line-height-base / 2);
      }
    }
  }

  &__body {
    background-color: @text-color;
    padding: 30px 25px;
  }

  .text-block {
    h1 + p,
    h2 + p,
    h3 + p {
      margin-top: 30px;

      &:before{
        display: none;
      }
    }
  }

  .button {
    .button.set-hollow-color();
  }

  @media (min-width: @screen-sm-min) {
    &__icon {
      padding-bottom: 50%;
      font-size: 20px;

      > div img {
        max-width: 120px;
        margin: 0 auto floor(@font-size-base * @line-height-base);
      }
    }

    &__body {
      padding: 60px 40px;
    }

    .text-block {
      h1 + p,
      h2 + p,
      h3 + p {
        margin-top: 45px;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    margin-bottom: 165px;

    &__head {
      .make-md-column(3);
      position: absolute;
      top: 130px;
      padding: 0;
      z-index: 1;
    }

    &__icon {
      padding-bottom: 100%;
      height: 0;
    }

    &__body {
      .make-md-column(10);
      .make-md-column-offset(1);
      padding-top: 130px;
      padding-bottom: 185px;
    }

    .set-odd-position();
  }
}
