.sub-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: fade(#fff, 95%);
  box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: @font-weight-semibold;
  padding: 20px 0;
  z-index: 99;
  transform: translateY(-100%);
  transition: transform .4s ease;
  display: none;

  .container-fluid {
    position: relative;
    padding-right: round(@grid-gutter-width / 2);
    padding-left: round(@grid-gutter-width / 2);
  }

  a,
  button {
    color: @text-color;
    text-decoration: none;
    transition: color .2s ease;

    &:hover,
    &.active {
      color: @brand-primary;
    }
  }

  &__logo {
    .size(100px, 42px);
    position: absolute;
    left: round(@grid-gutter-width / 2);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-size: contain;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/logo.svg');
  }

  &__menu {
   .list-inline();
    text-align: center;
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding-left: 7px;
      padding-right: 7px;

      a {
        color: @text-color;
        text-decoration: none;
        transition: color .2s ease;

        &:hover,
        &.active {
          color: @brand-primary;
        }
      }
    }
  }

  &__cta {
    .list-inline();
    position: absolute;
    top: 0;
    right: 0;
    margin-bottom: 0;
    font-weight: @font-weight-bold;
    text-align: right;
    z-index: 1;

    li {
      border-right: 2px solid @text-color;

      &:last-child {
        border-right: 0;
      }
    }

    button {
      outline: 0;
      border: 0;
      margin: 0;
      padding: 0;
      background: transparent;
      text-transform: uppercase;

      span {
        display: none;
      }

      .fa {
        position: relative;
        margin-left: 5px;
        transition: transform .2s ease;
      }

      &:hover {
        .fa {
          transform: translateY(-2px);
        }
      }
    }
  }

  &.toggle {
    transform: translateY(0%);
  }

  @media (min-width: @screen-sm-min) {
    display: block;

    &__logo {
      .size(140px, 42px);
    }

    .sub-header__menu {
      padding-left: 70px;
      padding-right: 30px;
      text-align: right;
    }
  }

  @media (min-width: @screen-md-min) {
    &__logo {
      .size(160px, 42px);
    }

    .sub-header__menu {
      padding-left: 0;
      padding-right: 0;
      text-align: center;

      li {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    font-size: 15px;

    button span {
      display: initial;
    }
  }
}
