.jumbotron {
  position: relative;
  text-align: center;
  color: #fff;
  background-color: @brand-primary;

  .button {
    .button.set-hollow-color();
  }

  &__image {
    .square(100%);
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      .square(100%);
      .gradient-bg();
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
  }

  &__content {
    .container();
    position: relative;
    z-index: 1;
    padding-top: 30%;
    padding-bottom: 30%;

    h1,
    h3 {
      .title();
    }

    h1 {
      .title.set-large-size();
    }

    h3 {
      .title.set-small-size();
    }

    > * {
      margin-bottom: 0 !important;

      + * {
        margin-top: 50px !important;
      }
    }

    .button {
      margin-bottom: 0;
    }

    @media (min-width: @screen-sm-min) {
      padding-top: 15%;
      padding-bottom: 15%;

      > * + * {
        margin-top: 64px !important;
      }
    }

    @media (min-width: @screen-md-min) {
      padding-top: 7.5%;
      padding-bottom: 7.5%;
    }
  }
}
