.card-list {
  position: relative;

  .card {
    margin-bottom: @grid-gutter-width;
  }

  @media (min-width: @screen-lg-min) {
    margin-left: (-@grid-gutter-width / 2);
    margin-right: (-@grid-gutter-width / 2);

    .row {
      transition: opacity .2s ease;
    }
  }

  @supports (display: flex) {
    width: auto !important;
    height: auto !important;

    .row {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: flex-start;

      &:before,
      &:after {
        display: none;
      }

      > *[class^="col-"] {
        float: none !important;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        display: flex;

        > * {
          width: 100%;
        }

        @media (max-width: @screen-xs-max) {
          width: 100%;
        }
      }
    }

    .card {
      // height: calc(100% ~'- @{grid-gutter-width}');
    }
  }
}

