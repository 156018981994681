.gallery {
  .set-default-color() {
    .gallery__button--next {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-arrow-right-dark.svg');
    }

    .gallery__button--prev {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-arrow-left-dark.svg');
    }
  }

  .set-light-color() {
    .gallery__button--next {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-arrow-right-light.svg');
    }

    .gallery__button--prev {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-arrow-left-light.svg');
    }
  }

  .set-default-color();
  position: relative;
  padding-bottom: 92%;
  margin-bottom: 56px;

  &__inner {
    .swiper-container();
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  &__wrapper {
    .swiper-wrapper();
    height: 100%;
  }

  &__slide {
    .swiper-slide();
    width: 100%/(12/8);
    height: 100%;
    box-shadow: 0 25px 25px -25px #000;
    overflow: hidden;
    background-size: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/loader-primary.svg');

    &__description {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: fade(#000, 70%);
      color: #fff;
      font-size: 80%;
      line-height: @line-height-small;
      padding: 10px 15px;
      transform: translateZ(0);
      pointer-events: none;
      display: none;
    }
  }

  &__media {
    .square(100%);
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateZ(0);
    outline: 0;
    display: block;
    transition: all .4s ease;

    a& {
      &:hover {
        transform: scale(1.05) translateZ(0);
      }
    }

    &--shadow {
      &:after {
        .square(100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: #000;
        opacity: .3;
        content: '';
      }

      + .gallery__slide__description {
        background-color: fade(#000, 30%);
      }
    }

    &--play {
      .square(60px);
      margin-left: -30px;
      margin-top: -30px;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 100%;
      border: 2px solid #fff;
      z-index: 2;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent transparent #fff;
        display: block;
        content: '';
        margin-top: -12px;
        margin-left: -7.5px;
        border-width: 12px 0 12px 20px;
      }
    }
  }

  &__lazy {
    opacity: 0;

    &--loaded {
      opacity: 1;
    }
  }

  &__button {
    .square(50px);
    position: absolute;
    bottom: -50px;
    left: 50%;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 50%;
    z-index: 3;
    cursor: pointer;
    transition: all .4s ease;

    &--next {
      margin-left: 35px;

      &:hover {
        background-position: 80%;
      }
    }

    &--prev {
      margin-left: -85px;

      &:hover {
        background-position: 20%;
      }
    }

    // &--disabled {
    //   opacity: .15;
    //   pointer-events: none;
    // }
  }

  &__pagination {
    .swiper-pagination();
    z-index: 3;
    font-size: @font-size-base;

    &--hidden {
      .swiper-pagination-hidden();
    }

    &--fraction {
      .swiper-pagination-fraction();
      bottom: -40px;
      font-weight: @font-weight-semibold;
    }
  }

  &__thumbnails {
    position: absolute;
    margin: 0 -4px;
    bottom: -85px;
  }

  &__thumbnails__slide {
    .swiper-slide();
    .square(64px);
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    outline: 0;
    display: inline-block;
    transition: all .4s ease;
    margin: 4px;
    background-color: #fff;

    &:after {
      .size(100%, 4px);
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-color: @text-color;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform .3s ease;
    }

    &--active,
    &:hover {
      &:after {
        transform: scaleY(1);
      }
    }
  }

  &--thumbnails {
    margin-bottom: 80px;

    .gallery__wrapper {
      margin-bottom: 0;
      padding-bottom: 4px;
    }

    .gallery__slide {
      box-shadow: none;
    }
  }

  @media (min-width: @screen-sm-min) {
    padding-bottom: 42.5%;

    &--4by3 {
      padding-bottom: 75% !important;
    }

    &--16by9 {
      padding-bottom: 56.25% !important;
    }

    &__slide {
      background-size: 65px;

      &__description {
        display: block;
      }
    }

    &__media--play {
      .square(90px);
      margin-left: -45px;
      margin-top: -45px;

      &:after {
        margin-top: -20px;
        margin-left: -10px;
        border-width: 20px 0 20px 30px;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    padding-bottom: 40.5%;

    &__slide {
      background-size: 80px;
    }

    &__media--play {
      .square(90px);
      margin-left: -45px;
      margin-top: -45px;

      &:after {
        margin-top: -18px;
        margin-left: -9px;
        border-width: 18px 0 18px 28px;
      }
    }

    &--pagination-align-left {
      .gallery__pagination {
        left: 75px;
        width: auto;
        right: auto;
      }

      .gallery__button {
        left: 0;

        &--next {
          margin-left: 125px;
        }

        &--prev {
          margin-left: 0;
        }
      }
    }

    &--pagination-align-right {
      .gallery__pagination {
        right: 75px;
        width: auto;
        left: auto;
      }

      .gallery__button {
        right: 0;
        left: auto;

        &--next {
          margin-right: 0;
        }

        &--prev {
          margin-right: 135px;
        }
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    padding-bottom: 38%;
  }
}

.gallery + .sharer {
  .margin-top();
  margin-bottom: 0;
  text-align: center;
  position: relative;
  z-index: 1;

  @media (min-width: @screen-md-min) {
    margin-top: -27px;
    float: right;
  }
}

@media (max-width: @screen-xs-max) {
  .lg-sub-html {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }
}
