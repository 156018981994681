label {
  text-transform: uppercase;
  font-weight: @font-weight-black;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;

  &.is-required::after {
    content: '*';
    margin-left: 3px;
    color: @brand-primary !important;
  }
}

.form-control {
  .set-gray-style() {
    border-color: fade(#5d5f67, 40%);
    color: #fff;
  }

  box-shadow: none;

  textarea& {
    height: 125px;
    resize: none;
  }
}

.checkbox {
  .set-gray-style() {
    span {
      border-color: fade(#5d5f67, 40%);
    }
  }

  margin: 0;

  label {
    position: relative;
    text-transform: none;
    padding: 0;
    vertical-align: top;
  }

  span {
    .square(16px);
    position: relative;
    top: -1px;
    display: inline-block;
    border: 1px solid @text-color;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 1px;
      left: 2px;
      content: '✔';
      line-height: 1;
      font-size: 12px;
      transition: transform .2s ease;
      transform: scale(0);
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  input[type="checkbox"]:checked + span::before {
    transform: scale(1);
  }
}

.form-group {
  > label {
    margin-bottom: 0;
  }
}

.submit-message {
  font-size: 90%;
  font-weight: 600;
}

.select-box {
  .form-group();

  .form-control {
    position: relative;
  }

  select {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: transparent;
    border: 0;

    option {
      color: #000;
    }
  }
}

.has-error .form-control {
  border-color: @brand-danger !important;
}

.form-group.has-error,
.select-box.has-error,
.checbox.has-error,
.radio.has-error {
  border-color: @brand-danger !important;
  color: @brand-danger !important;
}

.form .button[type="submit"],
.form .submit-message {
  float: right;
}

.form .submit-message {
  margin: 14px 24px;
}


