.logo-collection {
  position: relative;
  margin-top: -50px;

  ul {
    .list-unstyled();
    .container();
    margin-bottom: -20px;

    li {
      .make-xs-column(6);
    }
  }

  img {
    width: 100%;
  }

  @media (min-width: @screen-sm-min) {
    ul li {
      .make-sm-column(4);
    }
  }

  @media (min-width: @screen-md-min) {
    ul li {
      .make-md-column(3);
    }
  }
}
