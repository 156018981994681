ul,
ol {
  -webkit-padding-start: 20px;
}

.is-underlined {
  .set-color(@color: @text-color) {
    color: @color;

    &:after {
      border-bottom-color: @text-color;
    }
  }

  .set-default-color(){
    .set-color();
  }

  .set-light-color(){
    .set-color(#fff);
  }

  .set-default-color();
  position: relative;
  text-decoration: none !important;
  color: inherit;
  display: inline-block;
  transition: color .2s ease;

  &:before,
  &:after {
    position: relative;
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    top: -3px;
    content: '';
    transition: all .4s ease;
  }

  &:before {
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    z-index: 1;
    border-bottom-color: @brand-primary;
    transform: scaleX(0);
  }

  &:hover {
    text-decoration: none;

    &:after {
      opacity: 0.3;
    }

    &:before {
      transform: scaleX(1);
    }
  }

  &.light {
    .set-light-color();
  }
}

.list-inline {
  margin-left: -10px;

  > li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.text-normalize {
  text-transform: none;
}

.download {
  text-transform: uppercase;
  font-weight: @font-weight-bold;
  font-size: 12px;
  color: inherit !important;
  text-decoration: none !important;

  &:after {
    .square(24px);
    position: relative;
    display: inline-block;
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-download.svg');
    margin-left: 15px;
    vertical-align: middle;
    top: -2px;
    transition: transform .3s ease;
  }

  &:hover::after {
    transform: translateY(2px);
  }
}

@media(max-width: @screen-xs-max) {
  h1,
  .h1 {
    font-size: floor(@font-size-h1 * @font-size-mobile-factor / 1.2);
  }

  h2,
  .h2 {
    font-size: floor(@font-size-h2 * @font-size-mobile-factor / 1.2);
  }

  h3,
  .h3 {
    font-size: floor(@font-size-h3 * @font-size-mobile-factor / 1.2);
  }

  h4,
  .h4 {
    font-size: floor(@font-size-h4 * @font-size-mobile-factor);
  }

  h5,
  .h5 {
    font-size: floor(@font-size-h5 * @font-size-mobile-factor);
  }

  h6,
  .h6 {
    font-size: floor(@font-size-h6 * @font-size-mobile-factor);
  }
}
