.table {
  font-size: 15px;
  border-top: 2px solid #1e1e1c;

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border-top: 0;
    border-bottom: 1px solid @table-border-color;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &--fixed {
    table-layout: fixed;

    > thead > tr > *:not(:first-child),
    > tbody > tr > *:not(:first-child),
    > tfoot > tr > *:not(:first-child) {
      border-left: 1px solid @table-border-color;
      padding-left: @table-cell-padding + 5px;
    }

    @media(min-width: @screen-sm-min) {
      > thead > tr > *:first-child,
      > tbody > tr > *:first-child,
      > tfoot > tr > *:first-child {
        width: 40%;
      }
    }
  }

  &--mirror td{
    text-align: right;
  }
}
