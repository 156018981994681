.anchor-box {
  .gradient-bg();
  position: relative;
  display: table;
  text-align: center;
  color: #fff !important;
  height: 150px;
  padding: 20px;
  width: 100%;

  &:before {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background-color: @text-color;
    content: '';
    display: block;
    transition: transform 0.3s ease;
  }

  > span {
    .title();
    text-transform: none;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    z-index: 1;
  }

  &:focus,
  &:hover {
    text-decoration: none;

    &:before {
      transform: scaleX(0.98) scaleY(0.9);
    }
  }
}
