.filter-bar {
  .list-inline();
  margin-left: (-@grid-gutter-width / 2);

  a {
    color: inherit;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  a:not(.active) {
    opacity: 0.6;
    transition: opacity .2s ease;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }

  .badge {
    position: relative;
    font-size: 70%;
    vertical-align: top;
  }

  li {
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
  }

  @media (max-width: @screen-xs-max) {
    text-align: center;
    margin-left: 0;

    li {
      padding: 10px;
      width: 50%;
      display: block;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
