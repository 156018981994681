.contacts-map {
  position: relative;
  height: 500px;
  transition: opacity 0.2s ease;
  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}
