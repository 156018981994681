.img-overflow {
  width: 100%;
  max-width: none !important;

  @media (min-width: @screen-md-min) {
    width: 114%;
    margin-left: -7%;
  }

  @media (min-width: @screen-lg-min) {
    width: 132%;
    margin-left: -16%;
  }
}

&.img-center {
  margin: 0 auto;
}

