.pull-right-md {
  @media (min-width: @screen-md-min) {
    float: right;
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity .2s ease;
}

.wow {
  visibility: hidden;
}
