.media-box {
  .set-odd-position() {
    @media (min-width: @screen-md-min) {
      .media-box__image {
        .make-md-column-pull(1);
        right: 0;
      }

      .media-box__body {
        .make-md-column-offset(1);
        padding-top: 66px;
        padding-bottom: 120px;
        padding-left: 45px;
        padding-right: percentage((3.6 / @grid-columns));
      }
    }

    @media (min-width: @screen-lg-min) {
      .media-box__body {
        padding-left: 90px;
      }
    }
  }

  .set-even-position() {
    @media (min-width: @screen-md-min) {
      .media-box__image {
        .make-sm-column-push(1);
        left: 0;
      }

      .media-box__body {
        .make-md-column-offset(3);
        padding-top: 66px;
        padding-bottom: 120px;
        padding-right: 45px;
        padding-left: percentage((2.8 / @grid-columns));
      }
    }

    @media (min-width: @screen-lg-min) {
      .media-box__body {
        padding-right: 90px;
        padding-left: percentage((3.6 / @grid-columns));
      }
    }
  }

  .make-row(0);
  position: relative;
  color: #fff;
  margin-bottom: @media-box-margin-bottom-xs;

  .text-block {
    .text-block.set-line-color(#fff);

    h1,
    h2 {
      .title.set-medium-size();
      text-transform: none !important;
    }

    h3 {
      .title.set-small-size();
    }
  }

  .button {
    .button.set-hollow-color();
    margin-top: 8px;
  }

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 70%;
  }

  &__body {
    .gradient-bg();
    position: relative;
    padding: 30px;
  }

  @media (max-width: @screen-xs-max) {
    .text-block {
      h1 + p::before,
      h2 + p::before,
      h3 + p::before {
        margin-bottom: 28px;
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    margin-bottom: @media-box-margin-bottom-sm;

    &__image {
      padding-bottom: 60%;
    }

    &__body {
      padding: 60px;
    }
  }

  @media (min-width: @screen-md-min) {
    margin-bottom: @media-box-margin-bottom-md;

    &__image {
      .make-md-column(4);
      position: absolute;
      padding: 0;
      z-index: 1;
      height: 100%;
      margin-top: @media-box-image-margin-top-md;
    }

    &__body {
      .make-md-column(9);

      .text-block {
        h1 + p,
        h2 + p,
        h3 + p {
          margin-top: 24px;
        }
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    &__image {
      .make-lg-column(5);
      position: absolute;
      padding: 0;
    }

    &__body {
      .make-lg-column(8);
    }
  }

  .set-odd-position();
}
