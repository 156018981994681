.step-box-list {
  .container-fluid();

  .step-box {
    &:nth-child(even) {
      .step-box.set-even-position();
    }
  }

  @media (max-width: @screen-sm-max) {
    + .section--dark {
      padding-top: 0;
    }
  }

  @media (min-width: @screen-md-min) {
    .step-box {
      &__body::after {
        .size(22px, 262px);
        position: absolute;
        content: '';
        display: block;
        bottom: -213px;
        left: 50%;
        margin-left: -11px;
        background-image: data-uri('image/jpeg;base64', '../img/step-box-arrow.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        z-index: 1;
      }

      &:last-child {
        .step-box__body::after {
          display: none;
        }
      }
    }
  }
}
