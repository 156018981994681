.info-block {
  > .title {
    .margin-top();
  }

  &:last-child {
    // margin-bottom: 0 !important;

    > .anchor-box-list:last-child,
    > .card-list:last-child {
      overflow: hidden;
      margin-bottom: -@grid-gutter-width;
    }
  }

  .padding-top(2.5);

  @media (min-width: @screen-sm-min) {
    .padding-top(4);
  }
}
