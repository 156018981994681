.row--gutter-large {
  @media (min-width: @screen-md-min) {
    .make-row(100px);

    > *[class^="col-"] {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
