.spy-nav {
  display: none;

  ul {
    .list-unstyled();
    margin-bottom: 0;

    a {
      position: relative;
      text-decoration: none;
      transition: color .2s ease;

      &:before {
        position: absolute;
        left: -18px;
        top: 10px;
        bottom: 8px;
        width: 8px;
        background-color: @brand-primary;
        content: '';
        display: block;
        transition: transform .4s ease;
        transform-origin: right;
        transform: scaleX(0);
      }

      &:hover,
      &.active {
        color: @brand-primary;
      }
    }

    li {
      .title();
      .title.set-size(@title-font-size-mobile-large, @title-line-height-large);
      text-transform: none;

      &.active a {
        color: @brand-primary;

        &:before {
          transform: scaleX(1);
        }
      }

      &:only-child {
        display: none;
      }
    }
  }

  a {
    position: relative;
    color: inherit;
  }

  .back {
    &:before {
      .size(23px, 18px);
      position: relative;
      vertical-align: middle;
      display: inline-block;
      content: '';
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-arrow-left-dark.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-right: 13px;
      top: -2px;
      transition: transform .3s ease;
    }

    &:hover::before {
      transform: translateX(-4px);
    }

    ul {
      margin-top: 30px;
    }
  }

  @media(min-width: @screen-md-min) {
    display: block;

    ul {
      margin-top: 80px;

      li {
        .title.set-size(26px, @title-line-height-base);
        margin-bottom: 20px;
      }
    }

    &.affix,
    &.affix-bottom {
      top: 100px;
      padding-left: 50px;
      padding-right: 50px;
      margin-left: -50px;
      width: 25%;
    }
  }

  @media(min-width: @screen-lg-min) {
    ul li {
      .title.set-size(28px, @title-line-height-large);

      a::before {
        left: -18px;
        top: 11px;
        bottom: 11px;
        width: 8px;
      }
    }
  }
}
