.container--limited {
  max-width: 870px;
}

.container-fluid {
  max-width: 1700px;

  @media (min-width: @screen-md-min) {
    padding-left: 60px;
    padding-right: 60px;
  }
}
