.section {
  position: relative;
  padding-top: @section-padding-base-xs;
  padding-bottom: @section-padding-base-xs;

  &--primary {
    background-color: @brand-primary;

    a {
      color: darken(@brand-primary, 25%);
    }
  }

  &--dark {
    background-color: @text-color;

    a {
      color: lighten(@text-color, 30%);
    }

    .card {
      color: #fff;
    }
  }

  &--gray {
    background-color: @brand-secondary;
  }

  &--gradient {
    .gradient-bg();
  }

  &--primary,
  &--gradient,
  &--dark {
    color: #fff;

    a.is-underlined {
      .is-underlined.set-light-color();
      &:after {
        border-bottom-color: #fff;
      }
    }

    .text-block {
      .text-block.set-line-color(#fff);
    }

    .button {
      .button.set-hollow-color();
    }

    .form-control {
      .form-control.set-gray-style();
    }

    .checkbox {
      .checkbox.set-gray-style();
    }

    .gallery {
      .gallery.set-light-color();
    }

    .sharer {
      .sharer.set-light-color();
    }

    .sharer-dropdown {
      .sharer-dropdown.set-light-color();
    }

    .print-product {
      color: #fff;

      &:hover {
        color: @text-color;
      }
    }
  }

  &--gray {
    .card .card__head {
      background-color: #fff;
    }
  }

  .container:last-child,
  .container-fluid:last-child {
    > *:last-child {
      margin-bottom: 0;
    }

    > .anchor-box-list:last-child,
    > .card-list:last-child {
      overflow: hidden;
      margin-bottom: -@grid-gutter-width;
    }

    > form:last-child .button {
      margin-bottom: 0;
    }
  }

  > .step-box-list:last-child > .step-box:last-child {
    margin-bottom: -@section-padding-base-xs;
  }

  + .timeline {
    margin-top: round(-@section-padding-base-xs / 1.5);
    margin-bottom: @section-padding-base-xs;
  }

  + .logo-collection {
    padding-bottom: @section-padding-base-xs;
  }

  @media (min-width: @screen-sm-min) {
    padding-top: @section-padding-base-sm;
    padding-bottom: @section-padding-base-sm;

    + .step-box-list > .step-box:last-child {
      margin-bottom: 0;
    }

    + .timeline {
      margin-top: round(-@section-padding-base-sm / 2);
      margin-bottom: @section-padding-base-sm;
    }

    + .logo-collection {
      padding-bottom: @section-padding-base-sm;
    }

    &--xtiny {
      padding-top: floor(@section-padding-base-sm / 1.5);
      padding-bottom: floor(@section-padding-base-sm / 1.5);

      + .logo-collection {
        padding-bottom: floor(@section-padding-base-sm / 1.5);
      }
    }
  }

  @media (min-width: @screen-md-min) {
    padding-top: @section-padding-base-md;
    padding-bottom: @section-padding-base-md;

    + .media-box-list > .media-box:last-child {
      margin-bottom: @section-padding-base-md + @media-box-image-margin-top-md;
    }

    + .step-box-list > .step-box:last-child {
      margin-bottom: @section-padding-base-md;
    }

    + .logo-collection {
      padding-bottom: @section-padding-base-md;
    }

    + .timeline {
      margin-top: round(-@section-padding-base-md / 2.5);
      margin-bottom: @section-padding-base-md;
    }

    &--gray + .media-box-list {
      background-color: @brand-secondary;
    }

    &--tiny {
      padding-top: floor(@section-padding-base-md / 1.5);
      padding-bottom: floor(@section-padding-base-md / 1.5);

      + .media-box-list > .media-box:last-child {
        margin-bottom: floor(@section-padding-base-md / 1.5) + @media-box-image-margin-top-md;
      }

      + .step-box-list > .step-box:last-child {
        margin-bottom: floor(@section-padding-base-md / 1.5);
      }

      + .logo-collection {
        padding-bottom: floor(@section-padding-base-md / 1.5);
      }
    }

    &--xtiny {
      padding-top: floor(@section-padding-base-md / 3);
      padding-bottom: floor(@section-padding-base-md / 3);

      + .media-box-list > .media-box:last-child {
        margin-bottom: floor(@section-padding-base-md / 3) + @media-box-image-margin-top-md;
      }

      + .step-box-list > .step-box:last-child {
        margin-bottom: floor(@section-padding-base-md / 3);
      }

      + .logo-collection {
        padding-bottom: floor(@section-padding-base-md / 3);
      }
    }
  }
}

.section--default + .section--default,
.section--gradient + .section--gradient,
.section--dark + .section--dark,
.section--primary + .section--primary {
  padding-top: 0;
}

.section--gradient + .logo-collection {
  .gradient-bg();
}

.section--primary + .logo-collection {
  background-color: @brand-primary;
}

.section--dark + .logo-collection {
  background-color: @text-color;
}

.section--gray + .logo-collection {
  background-color: @brand-secondary;
}
