.spacer {
  .margin-bottom();
  position: relative;
  display: block;
  height: 0;

  &--small {
    .margin-bottom(0.5);
  }

  &--medium {
    .margin-bottom(1.5);
  }

  @media (min-width: @screen-sm-min) {
    &--large,
    &--xlarge {
      .margin-bottom(2);
    }
  }

  @media (min-width: @screen-md-min) {
    &--xlarge {
      .margin-bottom(2.4);
    }
  }
}
