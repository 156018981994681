#footer {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;

  ul {
    .list-unstyled();
  }

  img {
    max-width: 120px;
    margin-bottom: 12px;
  }

  .container-fluid {
    padding-right: round(@grid-gutter-width / 2);
    padding-left: round(@grid-gutter-width / 2);
  }

  .social {
    .list-inline();
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 0;
    font-size: 150%;

    li {
      padding: 0 6px;
    }

    + ul {
      margin-top: 0 !important;
    }
  }

  @media (min-width: @screen-sm-min) {
    ul {
      margin-bottom: 0;
    }

    .row {
      width: 100%;
      display: table;
      table-layout: fixed;

      > [class^="col-"] {
        display: table-cell;
        vertical-align: middle;
        float: none;
        text-align: center;

        > * + * {
          margin-top: floor((14px * @line-height-base) / 2);
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}
