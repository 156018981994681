.card {
  position: relative;
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: @line-height-computed;
  background-color: @brand-primary;
  transition: background-color .3s ease;

  &__head {
    position: relative;
    height: 0;
    padding-bottom: 72%;
    background-color: #fff;
  }

  &__image {
    .square(100%);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3D(-50%, -50%, 0);
      width: 100%;
      transition: transform .3s ease, opacity .2s ease;
    }
  }

  &__body {
    padding: 25px 22px;

    > * {
      margin: 0;

      + * {
        margin-top: 6px;
      }
    }

    .title {
      text-transform: none;
      font-size: @title-font-size-small;
    }

    small {
      display: block;
    }
  }

  &:focus,
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: darken(@brand-primary, 10%);

    .card__image img {
      will-change: transform;
      transform: translate3D(-50%, -50%, 0) scale(1.05);
    }
  }

  @media (min-width: @screen-sm-min) {
    &__body .title {
      font-size: (@title-font-size-base - 4px);
    }
  }
}
