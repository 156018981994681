.anchor-icon-list {
  .list-unstyled();
  font-size: 15px;
  border-top: 2px solid #1e1e1c;
  margin-bottom: 0;

  a {
    width: 100%;
    text-decoration: none;
    display: table;
    transition: background-color .2s ease;
    table-layout: fixed;
    line-height: @line-height-base;
    font-weight: @font-weight-bold;
    background-color: @brand-primary;
    color: #fff;
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);

    > span {
      padding-top: @table-cell-padding;
      padding-bottom: @table-cell-padding;
      vertical-align: top;
      display: table-cell;

      &:last-child {
        text-align: right;
      }
    }

    span.download::after {
      background-image: url(../img/ico-download-light.svg);
    }

    &:hover {
      background-color: lighten(@brand-primary, 5%);

      span.download::after {
        transform: translateY(2px);
      }
    }
  }

  > li {
    border-top: 0;
    border-bottom: 1px solid lighten(@brand-primary, 15%);
  }
}
