.language-dropdown {
  position: relative;
  display: inline-block;

  button {
    background-color: transparent;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
  }

  .dropdown-menu {
    border-radius: 0;
    padding: 0;
    min-width: inherit;
    margin-left: -11px;

    > li {
      border-bottom: 1px solid @dropdown-border;

      &:last-child {
        border-bottom: 0;
      }

      > a {
        padding: 3px 10px;
      }

      img {
        vertical-align: middle;
      }
    }
  }
}
