.page-header-slider {
  position: relative;

  .page-header {
    .square(100%);
    .swiper-slide();

    &__image {
      background-attachment: scroll !important;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    .square(60px);
    opacity: 0.5;
    display: block;
    text-align: center;
    border-radius: 100%;
    border: 0;
    padding: 0;
    outline: 0;
    background-image: none;
    background-color: darken(@brand-primary, 30%);
    margin-left: floor(@grid-gutter-width / 2);
    margin-right: floor(@grid-gutter-width / 2);
    transition: opacity .2s ease;
    display: none;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  .swiper-button-next {
    background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-arrow-right-light.svg');
  }

  .swiper-button-prev {
    background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/ico-arrow-left-light.svg');
  }

  @media (min-width: @screen-sm-min) {
    height: 100vh;
    min-height: 600px;
  }

  @media (min-width: @screen-md-min) {
    .swiper-button-next,
    .swiper-button-prev {
      display: block;
    }
  }
}
