/* Scrollbar */
.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0,0,0,0.1);
    .swiper-container-horizontal > & {
        position: absolute;
        left: 1%;
        bottom: 3px;
        z-index: 50;
        height: 5px;
        width: 98%;
    }
    .swiper-container-vertical > & {
        position: absolute;
        right: 3px;
        top: 1%;
        z-index: 50;
        width: 5px;
        height: 98%;
    }
}
.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0,0,0,0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
}
.swiper-scrollbar-cursor-drag {
    cursor: move;
}